import { render, staticRenderFns } from "./PersonalizePackagingTreatment.vue?vue&type=template&id=0a5ba3e7&scoped=true&"
import script from "./PersonalizePackagingTreatment.vue?vue&type=script&lang=js&"
export * from "./PersonalizePackagingTreatment.vue?vue&type=script&lang=js&"
import style0 from "./PersonalizePackagingTreatment.vue?vue&type=style&index=0&id=0a5ba3e7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a5ba3e7",
  null
  
)

export default component.exports