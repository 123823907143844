<template>
  <div class="packaging__container">
    <modal-header current-modal-name="personalize-packaging-modal">
      <span slot="header-title" class="no-search-field">
        {{ $t('dashboard.button.quickLabelPrint') }}
      </span>
    </modal-header>

    <div class="packaging__description">
      <div class="description__icon icon-personalize-packaging"></div>
      <div class="flex--align-center">
        {{ $t('dashboard.label.addFormulaDetails') }}
      </div>
    </div>
    <div class="packaging__list flex-column">
      <div class="list__columns flex space-between">
        <div class="column__container flex">
          <div class="column__patient-name flex--align-center no-search-field">
            {{ $t('dashboard.label.patientName') }}
          </div>
          <div class="column__created flex--center--center no-search-field">
            {{ $t('dashboard.label.created') }}
          </div>
          <div class="column__amount flex--center--center">
            {{ $t('dashboard.label.amount') }}
          </div>
        </div>
        <div class="column__search flex--align-center">
          <span
            v-if="!isSearchOpened"
            class="search__icon search__icon--closed icon-search pointer"
            aria-hidden="true"
            @click="openSearch"
          ></span>
          <div v-else class="search__field flex--align-center">
            <span class="search__icon ml15 mr10 icon-search pointer" aria-hidden="true"></span>
            <input
              ref="searchInputRef"
              type="text"
              class="search__input"
              :placeholder="$t('dashboard.info.searchByNameEmail')"
              :value="search"
              @input="debouncedSearchTreatments($event.target.value)"
            />
            <div
              class="search__close-icon icon-close custom-icon ml10 mr20"
              @click="closeSearch"
            ></div>
          </div>
        </div>
      </div>
      <div class="list__content">
        <div ref="treatmentList" class="treatment__list" infinite-wrapper>
          <personalize-packaging-treatment
            v-for="treatment in treatments"
            :key="treatment.id"
            :treatment="treatment"
          />
          <q-infinite-loading
            ref="treatmentLoader"
            spinner="waveDots"
            :force-use-infinite-wrapper="true"
            @infinite="infiniteHandler"
          >
            <div
              slot="no-results"
              class="treatment-loader flex--center--center treatment__no-result"
            >
              <div class="content__no-matching flex-column--align-center ">
                <div class="no-matching__icon icon-personalize-packaging-no-matching"></div>
                <p class="no-matching__text">
                  {{ $t('dashboard.label.noMatchingTreatmentsFound') }}
                </p>

                <button v-if="!search" class="rounded-button-white" @click="goToCreateTreatment">
                  {{ $t('dashboard.button.createNewTreatment') }}
                </button>
              </div>
            </div>
            <div slot="no-more" class="treatment-loader mt15 mb20 flex--center--center">
              <div class="treatment-loader__text">
                {{ $t('dashboard.label.treatmentOlderThan6Months') }}
              </div>
            </div>
          </q-infinite-loading>
        </div>
        <div v-if="!hasNoTreatments" class="list__gradient"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import QInfiniteLoading from 'vue-infinite-loading';
import PerfectScrollbar from 'perfect-scrollbar';
import { isEmpty, debounce } from 'lodash';

import ModalHeader from '@/modules/dashboard/components/dashboard/common/ModalHeader';
import PersonalizePackagingTreatment from '@/modules/dashboard/components/dashboard/personalize-packaging/PersonalizePackagingTreatment';

import { getPersonalizePackagingTreatments } from '@/modules/dashboard/api';
import { handleDashboardFunctionalButton } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';

import {
  DASHBOARD_FUNCTIONAL_BUTTON_TYPES,
  PERSONALIZE_PACKAGING_TREATMENT_DOWNLOAD_SIZE
} from '@/modules/dashboard/api/constants';

const BUTTON_TYPE = DASHBOARD_FUNCTIONAL_BUTTON_TYPES.CREATE_TREATMENT;

export default {
  name: 'PersonalizePackaging',
  components: {
    ModalHeader,
    PersonalizePackagingTreatment,
    QInfiniteLoading
  },
  data() {
    return {
      treatments: [],
      currentPage: 0,
      treatmentListScrollbar: null,
      isSearchOpened: false,
      search: ''
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID
    }),
    hasNoTreatments() {
      return isEmpty(this.treatments);
    }
  },
  created() {
    this.debouncedSearchTreatments = debounce(this.searchTreatments, 1000);
  },
  mounted() {
    this.treatmentListScrollbar = new PerfectScrollbar(this.$refs.treatmentList, {
      suppressScrollX: true,
      wheelPropagation: false
    });
  },
  beforeDestroy() {
    this.$refs.treatmentLoader.stateChanger.complete();
  },
  methods: {
    async openSearch() {
      this.isSearchOpened = true;

      await this.$nextTick();

      this.$refs.searchInputRef.focus();
    },
    closeSearch() {
      this.isSearchOpened = false;

      if (this.search) {
        this.searchTreatments('');
      }
    },
    searchTreatments(value) {
      this.search = value;

      this.currentPage = 0;
      this.treatments = [];

      this.$refs.treatmentLoader.stateChanger.reset();
    },
    infiniteHandler: debounce(
      function infiniteHandlerCallback($state) {
        this.activateInfiniteHandler($state);
      },
      500,
      {
        leading: false,
        trailing: true
      }
    ),
    async activateInfiniteHandler($state) {
      const { data: treatments } = await getPersonalizePackagingTreatments({
        doctorId: this.userId,
        page: this.currentPage,
        size: PERSONALIZE_PACKAGING_TREATMENT_DOWNLOAD_SIZE,
        partName: this.search
      });

      this.currentPage += 1;

      if (treatments.length) {
        this.treatments = this.treatments.concat(treatments);

        if (treatments.length < 1) {
          $state.complete();

          return;
        }

        $state.loaded();

        if (treatments.length < PERSONALIZE_PACKAGING_TREATMENT_DOWNLOAD_SIZE) {
          $state.complete();
        }
      } else {
        $state.complete();
      }

      this.treatmentListScrollbar.update();
    },
    goToCreateTreatment() {
      this.$emit('create-treatment');

      handleDashboardFunctionalButton(this.$router, this.$modal, BUTTON_TYPE);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/icons';
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/scss/helpers/colors';

.packaging {
  &__container {
    color: $grey-color;
    font-size: 15px;

    background-color: $white;
  }

  &__description {
    display: flex;

    margin: 20px 256px 15px 50px;
  }

  &__list {
    width: 100%;
    height: 450px;

    padding: 0 5px 50px;

    background-color: $background;
  }
}

.description {
  &__icon {
    flex-shrink: 0;

    margin-right: 15px;
  }
}

.list {
  &__columns {
    height: 40px;

    position: relative;

    margin: 0 25px 10px;

    border-bottom: 1px solid $dim-white;

    font-size: 13px;
  }

  &__content {
    height: 350px;
    position: relative;
  }

  &__gradient {
    height: 40px;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    background: linear-gradient(180deg, #f9fafc 0%, rgba(249, 250, 252, 0) 100%);
    transform: rotate(-180deg);
  }
}

.column {
  &__patient-name {
    width: 167px;

    margin-left: 80px;
  }

  &__created {
    width: 100px;

    padding-right: 15px;
  }

  &__amount {
    width: 75px;

    padding-right: 15px;
  }
}

.no-matching {
  &__icon {
    margin: 60px 0 50px;
  }

  &__text {
    font-size: 15px;
    color: $grey-color;

    margin-bottom: 30px;
  }
}

.search {
  &__icon {
    font-size: 18px;

    &--closed {
      margin-right: 15px;
    }
  }

  &__field {
    width: 288px;
    height: 100%;
    border-bottom: 1px solid $orange-color;
  }

  &__input {
    height: 100%;

    flex-grow: 1;

    border: none;
    outline: none;

    background-color: $background;
    color: $text-color;
  }
}

.treatment {
  &__list {
    height: 350px;

    position: relative;
    overflow: hidden;

    padding: 0 25px;

    background-color: $background;
  }

  &__no-result {
    height: 330px;
  }

  &-loader {
    width: 100%;

    &__text {
      height: 40px;
      padding: 10px 20px;

      display: flex;
      align-items: center;
      text-align: center;

      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 19px;

      color: $cancel-text-color;

      background: $dim-blue;
      border-radius: 8px;
    }
  }
}

@media (max-width: 767px) {
  .packaging {
    &__container {
      height: 100%;
    }

    &__description {
      display: none;
      height: auto;
    }

    &__list {
      height: calc(100vh - 52px);

      padding: 0;
      margin-bottom: 0;
    }
  }

  .treatment {
    &__list {
      height: 100%;

      padding: 0;
    }
  }

  .list {
    &__columns {
      justify-content: initial;
      margin: 15px 0 10px;
    }

    &__content {
      height: calc(100% - 65px);
    }
  }

  .column {
    &__container {
      width: calc(100% - 55px);
      order: 1;

      font-size: 10px;
    }

    &__patient-name {
      width: 25%;

      margin: 0 15px 0 0;
    }

    &__created {
      width: 10%;

      justify-content: flex-start;
    }

    &__created,
    &__amount {
      padding-right: 0;
    }

    &__search {
      width: 54px;

      justify-content: center;
      order: 0;

      position: relative;
    }
  }

  .no-matching {
    &__text {
      font-size: 12px;
    }
  }

  .search {
    &__field {
      width: 100vw;
      height: 100%;
      position: absolute;
      left: 0;
      border: none;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: $orange-color;
      }
    }

    &__icon {
      margin: 0 18px;

      &--closed {
        margin: 0;
      }
    }
  }
}

@media (max-width: 680px) {
  .column {
    &__created {
      width: 11%;
    }

    &__amount {
      display: none;
    }
  }
}

@media (max-width: 530px) {
  .column {
    &__patient-name {
      width: 66%;
    }

    &__created {
      width: 25%;
    }
  }
}

@media (max-width: 380px) {
  .column {
    &__patient-name {
      width: 57%;
    }
  }
}
</style>
